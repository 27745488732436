import CardGroup from './CardGroup'
import Body from './Body'
import Card from './CardItem'
import Title from './Title'

CardGroup.Body = Body
CardGroup.Card = Card
CardGroup.Title = Title

export default CardGroup
