import ShapeCta from './ShapeCta'
import Title from './Title'
import Shape from '../Shape'
import Body from './Body'
import Cta from './Cta'

ShapeCta.Title = Title
ShapeCta.Shape = Shape
ShapeCta.Body = Body
ShapeCta.Cta = Cta

export default ShapeCta
