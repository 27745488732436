import React from 'react'
import PropTypes from 'prop-types'
import ShapeLib from 'shape-library'
import { Box } from 'theme-ui'

const Shape = ({
  type,
  category,
  name,
  primaryColor,
  secondaryColor,
  size,
  sx,
  ...props
}) => {
  return (
    <Box __css={{ minHeight: size }} sx={{ ...sx }}>
      <ShapeLib
        type={type}
        category={category}
        name={name}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        size={size}
        {...props}
      />
    </Box>
  )
}

Shape.propTypes = {
  type: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  sx: PropTypes.shape({}),
}

Shape.defaultProps = {
  sx: null,
}

export default Shape
