import HeroCta from './HeroCta'
import HeroCtaButton from './HeroCtaButton'
import HeroCtaColumn from './HeroCtaColumn'
import HeroCtaHeading from './HeroCtaHeading'
import HeroCtaImage from './HeroCtaImage'
import HeroCtaIntro from './HeroCtaIntro'
import HeroCtaText from './HeroCtaText'

HeroCta.Button = HeroCtaButton
HeroCta.Column = HeroCtaColumn
HeroCta.Heading = HeroCtaHeading
HeroCta.Image = HeroCtaImage
HeroCta.Intro = HeroCtaIntro
HeroCta.Text = HeroCtaText

export default HeroCta
