import React from 'react'
import PropTypes from 'prop-types'
import Heading from '../Heading'

const BlockCtaIntro = ({ children, variant, ...props }) => (
  <Heading variant={variant} {...props}>
    {children}
  </Heading>
)

BlockCtaIntro.propTypes = {
  children: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

BlockCtaIntro.defaultProps = {
  variant: 'intro',
}

export default BlockCtaIntro
