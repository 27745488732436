import Footer from './Footer'
import FooterBranding from './FooterBranding'
import FooterIcon from './FooterIcon'
import FooterLink from './FooterLink'
import FooterLinkGroup from './FooterLinkGroup'
import FooterList from './FooterList'
import FooterMenuColumn from './FooterMenuColumn'
import FooterMenuItem from './FooterMenuItem'
import FooterMenuTitle from './FooterMenuTitle'
import FooterNavigation from './FooterNavigation'
import FooterNavItem from './FooterNavItem'

Footer.Branding = FooterBranding
Footer.Icon = FooterIcon
Footer.Link = FooterLink
Footer.LinkGroup = FooterLinkGroup
Footer.List = FooterList
Footer.MenuColumn = FooterMenuColumn
Footer.MenuItem = FooterMenuItem
Footer.MenuTitle = FooterMenuTitle
Footer.Navigation = FooterNavigation
Footer.NavItem = FooterNavItem

export default Footer
