import React from 'react'
import Proptypes from 'prop-types'
import Heading from '../Heading'

const Title = ({ align, children }) => {
  return (
    <Heading as="h3" sx={{ textAlign: align, mb: 'small', fontSize: '3xl' }}>
      {children}
    </Heading>
  )
}
Title.propTypes = {
  children: Proptypes.string.isRequired,
  align: Proptypes.string,
}
Title.defaultProps = {
  align: 'left',
}
export default Title
