import React from 'react'
import PropTypes from 'prop-types'

import ShapeCta from '../../components/ShapeCta'

const ShapeCtaBlock = ({ title, shape, text, ctaLink, ctaText, align }) => {
  const { type, category, name, primaryColor, secondaryColor, size } = shape
  return (
    <ShapeCta align={align}>
      {title && <ShapeCta.Title>{title}</ShapeCta.Title>}
      <ShapeCta.Shape
        type={type}
        category={category}
        name={name}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        size={size}
      />
      {text && <ShapeCta.Body>{text}</ShapeCta.Body>}
      {ctaLink && ctaText && (
        <ShapeCta.Cta to={ctaLink}>{ctaText}</ShapeCta.Cta>
      )}
    </ShapeCta>
  )
}

ShapeCtaBlock.propTypes = {
  title: PropTypes.string,
  shape: PropTypes.shape({
    type: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    primaryColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  }).isRequired,
  text: PropTypes.string,
  align: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
}

ShapeCtaBlock.defaultProps = {
  title: '',
  text: '',
  align: 'center',
  ctaLink: '',
  ctaText: '',
}

export default ShapeCtaBlock
