import React from 'react'
import PropTypes from 'prop-types'
import { Box, useThemeUI } from 'theme-ui'
import {
  CopyBlock as CopyComponent,
  Code,
  CodeBlock,
  shadesOfPurple,
  a11yDark,
  atomOneLight,
} from 'react-code-blocks'
import RichText from '../RichText'

const darkerGreen = '#2b7b03'
const darkerRed = '#d41706'

const customAtomOneLight = {}

Object.keys(atomOneLight).forEach((key) => {
  if (atomOneLight[key] === '#e45649') {
    customAtomOneLight[key] = darkerRed
    return
  }
  if (atomOneLight[key] === '#50a14f') {
    customAtomOneLight[key] = darkerGreen
    return
  }
  customAtomOneLight[key] = atomOneLight[key]
})

const themes = {
  a11yDark,
  atomOneLight: customAtomOneLight,
  shadesOfPurple,
}

const ConditionalWrapper = ({ isFull, theme, maxWidth, children }) => {
  return isFull ? (
    <Box
      __css={{
        bg: themes[theme].backgroundColor,
        '> *': { maxWidth, mx: 'auto' },
      }}
    >
      {children}
    </Box>
  ) : (
    <>{children}</>
  )
}

ConditionalWrapper.propTypes = {
  isFull: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  maxWidth: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
}

const CodeComponent = ({ isBlock, ...props }) => {
  return isBlock ? <CodeBlock {...props} /> : <Code {...props} />
}

CodeComponent.propTypes = {
  isBlock: PropTypes.bool.isRequired,
}

const CodeSnippet = ({
  code,
  caption,
  language,
  hideNumbers,
  isFull,
  highlight,
  theme,
  wrap,
  inline,
  copy,
  ...props
}) => {
  const showLineNumbers = inline ? false : !hideNumbers
  const {
    theme: {
      container: {
        wrapper: { maxWidth },
      },
    },
  } = useThemeUI()

  return (
    <Box
      __css={{
        m: 0,
        fontFamily: 'mono',
      }}
      as={inline ? 'span' : 'figure'}
      {...props}
    >
      <ConditionalWrapper isFull={isFull} maxWidth={maxWidth} theme={theme}>
        {copy ? (
          <CopyComponent
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={themes[theme]}
            highlight={highlight}
            wrapLines={wrap}
            codeBlock={!inline}
          />
        ) : (
          <CodeComponent
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={themes[theme]}
            highlight={highlight}
            wrapLines={wrap}
            isBlock={!inline}
          />
        )}
      </ConditionalWrapper>
      {!inline && caption && (
        <RichText
          as="figcaption"
          sx={{
            fontSize: 'tiny',
            fontStyle: 'italic',
            maxWidth: isFull ? maxWidth : null,
            mx: 'auto',
            px: 'xsmall',
          }}
        >
          {caption}
        </RichText>
      )}
    </Box>
  )
}

CodeSnippet.propTypes = {
  code: PropTypes.string.isRequired,
  caption: PropTypes.string,
  language: PropTypes.string.isRequired,
  hideNumbers: PropTypes.bool,
  theme: PropTypes.string,
  highlight: PropTypes.string,
  wrap: PropTypes.bool,
  inline: PropTypes.bool,
  copy: PropTypes.bool,
  isFull: PropTypes.bool,
}

CodeSnippet.defaultProps = {
  caption: '',
  hideNumbers: false,
  theme: 'shadesOfPurple',
  highlight: '',
  wrap: false,
  inline: false,
  copy: false,
  isFull: false,
}

export default CodeSnippet
