import React from 'react'
import PropTypes from 'prop-types'
import RichText from '../RichText'

const Body = ({ children }) => (
  <RichText sx={{ fontSize: '2xl', mb: 'small' }}>{children}</RichText>
)

Body.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Body
