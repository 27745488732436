const cta = {
  jamstack: {
    title: 'Ready to embrace the JAMstack revolution?',
    text:
      'Build fast and secure sites and apps delivered by pre-rendering files and serving them directly from a CDN, removing the requirement to manage or run web servers, databases and worry about traffic spikes.',
    ctaText: 'Work with us!',
    linkTo: '/contact',
  },
  agency: {
    title: 'We are a digital agency building great experiences',
    text:
      'We are a team of people who share the vision of modern websites and applications, that are fast, secure, reliable, and easy to maintain.',
    ctaText: 'Work with us!',
    linkTo: '/contact',
  },
  cms: {
    title: 'We are CMS specialist and Headless is our passion',
    text:
      'Our team has several years of experience working with traditional CMS as Drupal and Wordpress and more than a couple of years using those systems as Headless CMS and providing integrations with modern front-end tools as GatsbyJS, NextJS and others.',
    ctaText: "Let's talk",
    linkTo: '/contact',
  },
}

export default cta
