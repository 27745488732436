import React from 'react'
import PropTypes from 'prop-types'
import Grid from '../Grid'

const Body = ({ columns, children }) => {
  return <Grid columns={columns}>{children}</Grid>
}

Body.propTypes = {
  children: PropTypes.node.isRequired,
  columns: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ]),
}

Body.defaultProps = {
  columns: 1,
}

export default Body
