import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

const ShapeCta = ({ align, children }) => (
  <Box __css={{ textAlign: align, py: 'small', px: 'small' }}>{children}</Box>
)

ShapeCta.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  align: PropTypes.string,
}

ShapeCta.defaultProps = {
  align: '',
}

export default ShapeCta
