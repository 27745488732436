import React from 'react'
import PropTypes from 'prop-types'
import Heading from '../Heading'

const Title = ({ children }) => (
  <Heading sx={{ fontSize: '4xl' }}>{children}</Heading>
)

Title.propTypes = {
  children: PropTypes.string.isRequired,
}

export default Title
