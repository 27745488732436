const menu = [
  {
    icon: 'github',
    to: 'https://github.com/jmolivas',
    ariaLabel: 'github link',
  },
  {
    icon: 'twitter',
    to: 'https://twitter.com/jmolivas',
    ariaLabel: 'twitter link',
  },
  {
    icon: 'linkedin',
    to: 'https://www.linkedin.com/in/jmolivas',
    ariaLabel: 'linkedin link',
  },
]

export default menu
