import React from 'react'
import PropTypes from 'prop-types'
import Heading from '../Heading'

const BlockCtaHeading = ({ children, sx, ...props }) => {
  return (
    <Heading variant="big" sx={{ mb: 'small', ...sx }} {...props}>
      {children}
    </Heading>
  )
}

BlockCtaHeading.propTypes = {
  children: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
}

BlockCtaHeading.defaultProps = {
  sx: null,
}

export default BlockCtaHeading
