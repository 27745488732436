import Header from './Header'
import HeaderBranding from './HeaderBranding'
import HeaderMenuItem from './HeaderMenuItem'
import HeaderNavbar from './HeaderNavbar'

Header.Branding = HeaderBranding
Header.MenuItem = HeaderMenuItem
Header.Navbar = HeaderNavbar

export default Header
