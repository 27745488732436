import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'
/*eslint-disable*/
// Temporary workaround due to compatibility issues with Storybook and Gatsby components
let GatsbyLink
try {
  GatsbyLink = require('gatsby').Link
} catch (e) {
  GatsbyLink = require('gatsby-link').default
}
/* eslint-enable */

const Link = React.forwardRef(
  ({ to, children, variant, ariaLabel, ...props }, ref) => {
    const internal = /^\/(?!\/)/.test(to)
    const samePage = /^#/.test(to)

    return internal ? (
      <Box
        __themeKey="styles"
        ref={ref}
        aria-label={ariaLabel}
        variant={variant}
        as={GatsbyLink}
        to={to}
        {...props}
      >
        {children}
      </Box>
    ) : (
      <Box
        __themeKey="styles"
        ref={ref}
        aria-label={ariaLabel}
        variant={variant}
        as="a"
        href={to}
        target={!samePage ? '_blank' : ''}
        rel={!samePage ? 'noopener noreferrer' : ''}
        {...props}
      >
        {children}
      </Box>
    )
  }
)

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  variant: PropTypes.string,
}

Link.defaultProps = {
  variant: 'a',
  ariaLabel: null,
}

export default Link
