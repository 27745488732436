import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

const CardImage = ({ children }) => {
  return (
    <Box __css={{ py: 'small' }} variant="wrapper" __themeKey="container">
      {children}
    </Box>
  )
}

CardImage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CardImage
