import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Button'
import Link from '../Link'

const BlockCtaButton = React.forwardRef(
  ({ children, variant, to, sx, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        sx={{ mt: 15, ...sx }}
        variant={variant}
        to={to}
        as={Link}
        {...props}
      >
        {children}
      </Button>
    )
  }
)

BlockCtaButton.propTypes = {
  children: PropTypes.string.isRequired,
  variant: PropTypes.string,
  to: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
}

BlockCtaButton.defaultProps = {
  variant: 'default',
  sx: null,
}

export default BlockCtaButton
