import React from 'react'
import PropTypes from 'prop-types'
import Card from '../Card'
import Link from '../Link'

const CardItem = ({ intro, link, image, title, text, ctaText, align }) => {
  return (
    <Card align={align}>
      {intro && <Card.Intro>{intro}</Card.Intro>}
      {link && image && (
        <Link to={link} variant="default">
          <Card.Image image={image} />
        </Link>
      )}
      {!link && image && <Card.Image image={image} />}
      <Card.Heading>{title}</Card.Heading>
      {text && <Card.Text sx={{ fontSize: 'lg' }}>{text}</Card.Text>}
      {link && ctaText && (
        <Card.Button type="primary" to={link}>
          {ctaText}
        </Card.Button>
      )}
    </Card>
  )
}

CardItem.propTypes = {
  intro: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  image: PropTypes.shape({}),
  title: PropTypes.string,
  text: PropTypes.string,
  ctaText: PropTypes.string,
  align: PropTypes.string,
}

CardItem.defaultProps = {
  intro: undefined,
  link: undefined,
  image: undefined,
  title: undefined,
  text: undefined,
  ctaText: undefined,
  align: undefined,
}

export default CardItem
