import React, { useState, useEffect } from 'react' // eslint-disable-line
import PropTypes from 'prop-types'
/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { SkipNavContent } from '@reach/skip-nav'
import {
  theme,
  ThemeProvider,
  Header,
  Footer,
  Logo,
  MobileMenu,
  Section,
} from 'gatsby-theme-octahedroid'
import footer from '../footer'
import menu from '../menu'

const Layout = ({ children }) => {
  const [scrolledMenu, setScrolledMenu] = useState(false)
  const [showSidebar, setShowSidebar] = useState(false)
  const handleScroll = (event) => {
    const target = event.target || event.srcElement
    setScrolledMenu(target.scrollingElement.scrollTop > 30)
  }
  const handleShowSidebar = () => {
    setShowSidebar(!showSidebar)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll, { passive: true })
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          pt: ['large', null, 'xlarge'],
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Header shadow={scrolledMenu}>
          <Header.Branding to="/" ariaLabel="Back to Home">
            <Logo />
          </Header.Branding>
          <Header.Navbar handleShowSidebar={handleShowSidebar}>
            {menu &&
              menu.map((item) => (
                <Header.MenuItem
                  key={item.name}
                  to={item.to}
                  variant={item.active ? 'menuItemLinkActive' : 'menuItemLink'}
                >
                  {item.name}
                </Header.MenuItem>
              ))}
          </Header.Navbar>
        </Header>
        <MobileMenu
          showSidebar={showSidebar}
          handleShowSidebar={handleShowSidebar}
        >
          <MobileMenu.Navbar>
            {menu &&
              menu.map((item) => (
                <MobileMenu.Item key={item.name} to={item.to}>
                  {item.name}
                </MobileMenu.Item>
              ))}
          </MobileMenu.Navbar>
        </MobileMenu>
        <Box __css={{ flex: 1 }}>
          <SkipNavContent>{children}</SkipNavContent>
        </Box>
        <Section variant="dark">
          <Footer>
            <Footer.Branding to="/" ariaLabel="Back to Home">
              <Logo width={200} fill="#fff" />
            </Footer.Branding>
            <Footer.LinkGroup>
              {footer &&
                footer.map((item) => (
                  <Footer.Link
                    to={item.to}
                    variant="light"
                    ariaLabel={item.ariaLabel}
                    key={item.ariaLabel}
                  >
                    <Footer.Icon icon={item.icon} variant="social" />
                  </Footer.Link>
                ))}
            </Footer.LinkGroup>
          </Footer>
        </Section>
      </Box>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
