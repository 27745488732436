import React from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'theme-ui'
import Section from '../Section'
import Grid from '../Grid'

const BlogTeaserList = ({ children }) => {
  return (
    <Section>
      <Divider />
      <Grid columns={2}>{children}</Grid>
    </Section>
  )
}

BlogTeaserList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
}

export default BlogTeaserList
