const menu = [
  {
    name: 'Home',
    to: '/',
    active: false,
  },
  {
    name: 'Contact',
    to: '/contact/',
    active: false,
  },
]

export default menu
