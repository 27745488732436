import React from 'react'
import PropTypes from 'prop-types'
import uuid from 'react-uuid'
import Grid from '../../components/Grid'
import Card from '../../components/Card'
import CardImage from '../../components/CardImage'

const CardImageGroupBlock = ({ title, columns, cards, align }) => {
  return (
    <CardImage>
      {title && <CardImage.Heading title={title} align={align} />}
      <Grid columns={columns}>
        {cards.map(({ image }) => (
          <Card align={align} key={uuid()}>
            <Card.Image image={image} />
          </Card>
        ))}
      </Grid>
    </CardImage>
  )
}

CardImageGroupBlock.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ]),
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }),
    })
  ).isRequired,
  align: PropTypes.string,
}

CardImageGroupBlock.defaultProps = {
  title: '',
  columns: 3,
  align: 'left',
}

export default CardImageGroupBlock
