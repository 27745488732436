import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'
import RichText from '../RichText'
import Link from '../Link'

const Quote = ({ children, quotes, source, sourceTo, sameLine, ...props }) => {
  const linkElement = sourceTo ? (
    <Link sx={{ color: 'inherit', textDecoration: 'underline' }} to={sourceTo}>
      {source}
    </Link>
  ) : (
    source
  )
  return (
    <Box
      as="blockquote"
      __css={{
        pl: 'xsmall',
        py: 'tiny',
        borderLeft: '3px solid black',
        fontSize: '1.1rem',
        fontStyle: 'italic',
      }}
      {...props}
    >
      <RichText>
        {quotes && '“'}
        {children}
        {quotes && '”'}
        {source && sameLine && (
          <>
            {' — '}
            {linkElement}
          </>
        )}
      </RichText>
      {source && !sameLine && (
        <>
          {' — '}
          {linkElement}
        </>
      )}
    </Box>
  )
}

Quote.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  quotes: PropTypes.bool,
  source: PropTypes.string,
  sourceTo: PropTypes.string,
  sameLine: PropTypes.bool,
}

Quote.defaultProps = {
  quotes: false,
  source: '',
  sourceTo: '',
  sameLine: false,
}

export default Quote
