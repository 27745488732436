import React from 'react'
import PropTypes from 'prop-types'
import Card from '../Card'
import Icon from '../Icon'

const BlogTeaserItem = ({ image, to, published, title }) => {
  return (
    <Card
      to={to}
      sx={{
        m: ['small', null, 'medium'],
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '5px',
        transition: '0.1s',
        ':hover': {
          boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.2);',
        },
      }}
    >
      <Card.Image
        image={image}
        sx={{
          border: '1px solid #CCC',
        }}
      />

      <Card.Text sx={{ fontSize: 'tiny', pt: 'small', mb: 'tiny' }}>
        <Icon icon="calendar" sx={{ mr: 'xsmall' }} />
        {published}
      </Card.Text>
      <Card.Heading sx={{ minHeight: '140', flex: '1', mt: 'tiny' }}>
        {title}
      </Card.Heading>
      <Card.Text sx={{ pt: 'xsmall', fontSize: 'large', color: 'secondary' }}>
        Learn More »
      </Card.Text>
    </Card>
  )
}

BlogTeaserItem.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      fluid: PropTypes.shape({}).isRequired,
      alt: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      fixed: PropTypes.shape({}).isRequired,
      alt: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  to: PropTypes.string,
  published: PropTypes.string,
  title: PropTypes.string,
}

BlogTeaserItem.defaultProps = {
  to: '',
  published: '',
  title: '',
}

export default BlogTeaserItem
