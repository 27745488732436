import Share from './Share'
import ShareHeading from './ShareHeading'
import ShareFacebook from './ShareFacebook'
import ShareLinkedin from './ShareLinkedin'
import ShareReddit from './ShareReddit'
import ShareTwitter from './ShareTwitter'
import ShareWhatsapp from './ShareWhatsapp'
import ShareContainer from './ShareContainer'

Share.Heading = ShareHeading
Share.Facebook = ShareFacebook
Share.Linkedin = ShareLinkedin
Share.Reddit = ShareReddit
Share.Twitter = ShareTwitter
Share.Whatsapp = ShareWhatsapp
Share.Container = ShareContainer

export default Share
