import React from 'react'
import PropTypes from 'prop-types'
import uuid from 'react-uuid'
import CardGroup from '../../components/CardGroup'

const CardGroupBlock = ({ title, columns, cards, align }) => {
  return (
    <CardGroup>
      {title && <CardGroup.Title align={align}>{title}</CardGroup.Title>}
      <CardGroup.Body columns={columns}>
        {cards.map(
          ({ intro, image, title: cardTitle, text, linkTo, ctaText }) => (
            <CardGroup.Card
              key={uuid()}
              align={align}
              intro={intro}
              link={linkTo}
              image={image}
              title={cardTitle}
              text={text}
              ctaText={ctaText}
            />
          )
        )}
      </CardGroup.Body>
    </CardGroup>
  )
}

CardGroupBlock.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ]),
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      intro: PropTypes.string,
      image: PropTypes.oneOfType([
        PropTypes.shape({
          src: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }),
        PropTypes.shape({
          fluid: PropTypes.shape({}).isRequired,
          alt: PropTypes.string.isRequired,
        }),
        PropTypes.shape({
          fixed: PropTypes.shape({}).isRequired,
          alt: PropTypes.string.isRequired,
        }),
      ]),
      title: PropTypes.string.isRequired,
      text: PropTypes.string,
      linkTo: PropTypes.string,
      ctaAs: PropTypes.string,
      ctaText: PropTypes.string,
    })
  ).isRequired,
  align: PropTypes.string,
}

CardGroupBlock.defaultProps = {
  title: '',
  columns: 3,
  align: 'left',
}

export default CardGroupBlock
