import React from 'react'
import Proptypes from 'prop-types'
import { Box } from 'theme-ui'

const CardGroup = ({ children, ...props }) => {
  return (
    <Box
      __css={{
        py: 'small',
      }}
      variant="wrapper"
      __themeKey="container"
      {...props}
    >
      {children}
    </Box>
  )
}
CardGroup.propTypes = {
  children: Proptypes.node.isRequired,
}

export default CardGroup
