import React from 'react'
import PropTypes from 'prop-types'
import CodeSnippet from '../../components/CodeSnippet'
import Section from '../../components/Section'

const CodeSnippetBlock = ({
  children,
  code,
  caption,
  language,
  hideNumbers,
  isFull,
  highlight,
  theme,
  wrap,
  inline,
  copy,
  ...props
}) => {
  return (
    <Section isFull={isFull} {...props}>
      <CodeSnippet
        code={code || children}
        caption={caption}
        language={language}
        hideNumbers={hideNumbers}
        isFull={isFull}
        highlight={highlight}
        theme={theme}
        wrap={wrap}
        inline={inline}
        copy={copy}
      />
    </Section>
  )
}

CodeSnippetBlock.propTypes = {
  children: PropTypes.string,
  code: PropTypes.string,
  caption: PropTypes.string,
  language: PropTypes.string.isRequired,
  hideNumbers: PropTypes.bool,
  theme: PropTypes.string,
  highlight: PropTypes.string,
  wrap: PropTypes.bool,
  inline: PropTypes.bool,
  copy: PropTypes.bool,
  isFull: PropTypes.bool,
}

CodeSnippetBlock.defaultProps = {
  children: '',
  code: '',
  caption: '',
  hideNumbers: false,
  theme: 'atomOneLight',
  highlight: '',
  wrap: false,
  inline: false,
  copy: false,
  isFull: false,
}

export default CodeSnippetBlock
