import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

const BlockCta = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Box
      ref={ref}
      __css={{
        px: 'small',
        py: ['medium', null, 'xlarge'],
        textAlign: 'center',
      }}
      {...props}
    >
      {children}
    </Box>
  )
})

BlockCta.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default BlockCta
