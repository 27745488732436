import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Button'
import Link from '../Link'

const Cta = ({ to, children }) => (
  <Button as={Link} sx={{ mt: 'tiny', mb: 'small' }} to={to}>
    {children}
  </Button>
)

Cta.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
}

export default Cta
