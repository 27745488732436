import BlogHead from './BlogHead'
import BlogHeadImage from './BlogHeadImage'
import BlogHeadTitle from './BlogHeadTitle'
import BlogHeadAuthor from './BlogHeadAuthor'
import BlogHeadContainer from './BlogHeadContainer'
import BlogHeadDate from './BlogHeadDate'
import BlogHeadCredits from './BlogHeadCredits'

BlogHead.Image = BlogHeadImage
BlogHead.Title = BlogHeadTitle
BlogHead.Author = BlogHeadAuthor
BlogHead.Date = BlogHeadDate
BlogHead.Container = BlogHeadContainer
BlogHead.Credits = BlogHeadCredits

export default BlogHead
