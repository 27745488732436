import React from 'react'
import PropTypes from 'prop-types'
import RichText from '../RichText'

const BlockCtaText = ({ children, sx, ...props }) => (
  <RichText
    variant="xxl"
    sx={{
      mb: 5,
      mx: 'auto',
      ...sx,
    }}
    {...props}
  >
    {children}
  </RichText>
)

BlockCtaText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  sx: PropTypes.shape({}),
}

BlockCtaText.defaultProps = {
  sx: null,
}

export default BlockCtaText
