import Card from './Card'
import CardButton from './CardButton'
import CardHeading from './CardHeading'
import CardImage from './CardImage'
import CardIntro from './CardIntro'
import CardText from './CardText'
import CardRichText from './CardRichText'

Card.Button = CardButton
Card.Heading = CardHeading
Card.Image = CardImage
Card.Intro = CardIntro
Card.Text = CardText
Card.RichText = CardRichText

export default Card
