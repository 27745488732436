import React from 'react'
import PropTypes from 'prop-types'
import BlockCta from '../../components/BlockCta'
import Section from '../../components/Section'

const SectionCtaBlock = ({
  intro,
  title,
  text,
  linkTo,
  icon,
  ctaText,
  variant,
}) => {
  const dark = variant === 'dark'
  return (
    <Section variant={variant}>
      <BlockCta>
        {icon && <BlockCta.Icon icon={icon} />}
        {intro && (
          <BlockCta.Intro variant={dark ? 'introLight' : 'intro'}>
            {intro}
          </BlockCta.Intro>
        )}
        {title && <BlockCta.Heading>{title}</BlockCta.Heading>}
        <BlockCta.Text>{text}</BlockCta.Text>
        {linkTo && ctaText && (
          <BlockCta.Button variant={dark ? 'light' : 'dark'} to={linkTo}>
            {ctaText}
          </BlockCta.Button>
        )}
      </BlockCta>
    </Section>
  )
}

SectionCtaBlock.propTypes = {
  intro: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  ctaText: PropTypes.string,
  icon: PropTypes.string,
  variant: PropTypes.oneOf(['light', 'dark', 'transparent']),
}

SectionCtaBlock.defaultProps = {
  intro: '',
  title: '',
  linkTo: '',
  ctaText: '',
  icon: '',
  variant: 'light',
}

export default SectionCtaBlock
