import BlockCta from './BlockCta'
import BlockCtaButton from './BlockCtaButton'
import BlockCtaHeading from './BlockCtaHeading'
import BlockCtaIntro from './BlockCtaIntro'
import BlockCtaText from './BlockCtaText'
import BlockCtaIcon from './BlockCtaIcon'

BlockCta.Button = BlockCtaButton
BlockCta.Heading = BlockCtaHeading
BlockCta.Icon = BlockCtaIcon
BlockCta.Intro = BlockCtaIntro
BlockCta.Text = BlockCtaText

export default BlockCta
