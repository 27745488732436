import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider as ThemeProviderUi } from 'theme-ui'

const ThemeProvider = ({ children, theme }) => {
  return <ThemeProviderUi theme={theme}>{children}</ThemeProviderUi>
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape({}).isRequired,
}

export default ThemeProvider
