import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Grid, Card, Icon, InfiniteScroll } from 'gatsby-theme-octahedroid'

const imageQuery = graphql`
  query blogImageQuery {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fields: { template: { eq: "blog" } } }
    ) {
      nodes {
        frontmatter {
          path
          title
          date(formatString: "MMMM DD, YYYY")
          header {
            image {
              src {
                childImageSharp {
                  fluid(maxWidth: 540, maxHeight: 400, quality: 85) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
  }
`

const BlogListing = () => {
  const [hasMore, setHasMore] = useState(true)
  const {
    allMdx: { nodes },
  } = useStaticQuery(imageQuery)

  const [items, setItems] = useState(nodes.slice(0, 4))

  const fetchNext = () => {
    setItems([...items, ...nodes.slice(items.length, items.length + 4)])
    if (items.length === nodes.length) {
      setHasMore(false)
    }
  }

  return (
    <InfiniteScroll
      dataLength={items.length}
      nextHandler={() => fetchNext()}
      hasMore={hasMore}
    >
      <Grid columns={2}>
        {items.map(({ frontmatter }) => {
          const image = {
            fluid: frontmatter.header.image.src.childImageSharp.fluid,
            alt: frontmatter.header.image.alt,
          }
          return (
            <Card
              to={frontmatter.path}
              sx={{
                mx: ['small', null, 'medium'],
                my: 'xsmall',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '5px',
                transition: '0.1s',
                ':hover': {
                  boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.2);',
                },
              }}
              key={frontmatter.title}
            >
              <Card.Image
                image={image}
                sx={{
                  border: '1px solid #CCC',
                }}
              />

              <Card.Text sx={{ fontSize: 'tiny', pt: 'small', mb: 'tiny' }}>
                <Icon icon="calendar" sx={{ mr: 'xsmall' }} />
                {frontmatter.date}
              </Card.Text>
              <Card.Heading sx={{ minHeight: '140', flex: '1', mt: 'tiny' }}>
                {frontmatter.title}
              </Card.Heading>
              <Card.Text
                sx={{ pt: 'xsmall', fontSize: 'large', color: 'secondary' }}
              >
                Read More »
              </Card.Text>
            </Card>
          )
        })}
      </Grid>
    </InfiniteScroll>
  )
}

export default BlogListing
