import React from 'react'
import PropTypes from 'prop-types'
import Section from '../../components/Section'
import Quote from '../../components/Quote'

const QuoteBlock = ({
  children,
  quotes,
  source,
  sourceTo,
  sameLine,
  ...props
}) => {
  return (
    <Section>
      <Quote
        quotes={quotes}
        source={source}
        sourceTo={sourceTo}
        sameLine={sameLine}
        {...props}
      >
        {children}
      </Quote>
    </Section>
  )
}

QuoteBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  quotes: PropTypes.bool,
  source: PropTypes.string,
  sourceTo: PropTypes.string,
  sameLine: PropTypes.bool,
}

QuoteBlock.defaultProps = {
  quotes: false,
  source: '',
  sourceTo: '',
  sameLine: false,
}

export default QuoteBlock
