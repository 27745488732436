import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

const GridColumn = ({ columns, rows, children, ...props }) => {
  return (
    <Box
      __css={{
        gridColumn: columns ? `span ${columns}` : 'auto',
        gridRow: rows ? `span ${rows}` : 'auto',
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

GridColumn.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  columns: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

GridColumn.defaultProps = {
  columns: '',
  rows: '',
}

export default GridColumn
