import React from 'react'
import PropTypes from 'prop-types'
import Heading from '../Heading'

const CardImageHeading = ({ title, align }) => {
  return (
    <Heading
      as="h3"
      sx={{ textAlign: align, mb: 'small', h3: { fontSize: '3xl' } }}
    >
      {title}
    </Heading>
  )
}

CardImageHeading.propTypes = {
  title: PropTypes.string.isRequired,
  align: PropTypes.string,
}

CardImageHeading.defaultProps = {
  align: 'left',
}

export default CardImageHeading
