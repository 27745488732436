import React from 'react' // eslint-disable-line
import PropTypes from 'prop-types'
import {
  theme,
  Heading,
  CodeSnippetBlock,
  CodeSnippet,
  Grid,
  Button,
  Card,
  Image,
  RichText,
  Section,
  CardGroupBlock,
  ContactBlock,
  CardImageGroupBlock,
  HeroCtaBlock,
  QuoteBlock,
  SectionCtaBlock,
  ShapeCtaBlock,
  ShareBlock,
  Icon,
  TextBlock,
  HeroTextBlock,
  VideoEmbed,
  Link,
} from 'gatsby-theme-octahedroid'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Box, Divider } from 'theme-ui'
import ReactEmbedGist from 'react-gists'
import BlogListing from './blog-listing'

const Width = ({ children, ...props }) => {
  return (
    <Box __css={{ ...theme.container.wrapper }} {...props}>
      {children}
    </Box>
  )
}

Width.propTypes = {
  children: PropTypes.node.isRequired,
}

const Gist = ({ gist, file }) => (
  <Width>
    <ReactEmbedGist id={gist} file={file} />
  </Width>
)

Gist.propTypes = {
  gist: PropTypes.string.isRequired,
  file: PropTypes.string,
}

Gist.defaultProps = {
  file: '',
}

const CodeWrapper = ({ className, children }) => (
  <CodeSnippetBlock
    code={children}
    language={className.slice(9)}
    isFull
    hideNumbers
  />
)

CodeWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
}

CodeWrapper.defaultProps = {
  className: 'language-php',
}

const InlineCodeWrapper = ({ children }) => (
  <CodeSnippet code={children} inline theme="atomOneLight" language="text" />
)

InlineCodeWrapper.propTypes = {
  children: PropTypes.string.isRequired,
}

const ImageWrapper = ({ src, alt, title }) => (
  <Image image={{ src, alt }} caption={title} sx={{ py: 'small' }} />
)

ImageWrapper.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string,
}

ImageWrapper.defaultProps = {
  title: '',
}

const HeadingWrapper = ({ as, children }) => {
  return (
    <Width sx={{ mt: 'medium' }}>
      <Heading as={as}>{children}</Heading>
    </Width>
  )
}

HeadingWrapper.propTypes = {
  as: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
}

const ListWrapper = ({ as, children }) => (
  <Width as={as} sx={{ my: 'small', '& ul': { my: 0, pl: 'medium' } }}>
    {children}
  </Width>
)

ListWrapper.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.elementType),
  ]).isRequired,
  children: PropTypes.string.isRequired,
}

const LinkWrapper = ({ href, ...props }) => <Link to={href} {...props} />

LinkWrapper.propTypes = {
  href: PropTypes.string.isRequired,
}

const shortcodes = {
  a: LinkWrapper,
  h1: (props) => <HeadingWrapper as="h1" {...props} />,
  h2: (props) => <HeadingWrapper as="h2" {...props} />,
  h3: (props) => <HeadingWrapper as="h3" {...props} />,
  h4: (props) => <HeadingWrapper as="h4" {...props} />,
  h5: (props) => <HeadingWrapper as="h5" {...props} />,
  h6: (props) => <HeadingWrapper as="h6" {...props} />,
  inlineCode: (props) => <InlineCodeWrapper {...props} />,
  p: (props) => (
    <Width
      sx={{
        my: 'small',
        '.gatsby-resp-image-background-image': { display: 'none !important' },
      }}
    >
      <RichText as="p" {...props} />
    </Width>
  ),
  hr: () => (
    <Width sx={{ mt: 'small' }}>
      <Divider />
    </Width>
  ),
  img: ImageWrapper,
  ul: (props) => <ListWrapper as="ul" {...props} />,
  ol: (props) => <ListWrapper as="ol" {...props} />,
  code: CodeWrapper,
  blockquote: QuoteBlock,
  Box,
  Grid: (props) => (
    <Width>
      <Grid {...props} />
    </Width>
  ),
  GridColumn: Grid.Column,
  Button,
  Card,
  CardButton: Card.Button,
  CardImage: Card.Image,
  CardHeading: Card.Heading,
  CardIntro: Card.Intro,
  CardText: Card.RichText,
  Image: (props) => (
    <Width>
      <Image {...props} />
    </Width>
  ),
  Heading: (props) => (
    <Width>
      <Heading {...props} />
    </Width>
  ),
  Icon,
  Section,
  VideoEmbed: (props) => (
    <Width sx={{ px: ['xsmall', 'medium', 'large'], py: 'small' }}>
      <VideoEmbed {...props} />
    </Width>
  ),
  CodeSnippet: CodeSnippetBlock,
  CardGroup: CardGroupBlock,
  CardImageGroup: CardImageGroupBlock,
  Contact: ContactBlock,
  HeroCta: HeroCtaBlock,
  HeroText: HeroTextBlock,
  Quote: QuoteBlock,
  SectionCta: SectionCtaBlock,
  ShapeCta: ShapeCtaBlock,
  ShareBlock,
  TextBlock,
  Gist,
  BlogListing: () => (
    <Width>
      <BlogListing />
    </Width>
  ),
}

const Provider = ({ children }) => {
  return (
    <MDXProvider components={shortcodes}>
      <MDXRenderer>{children}</MDXRenderer>
    </MDXProvider>
  )
}

Provider.propTypes = {
  children: PropTypes.string.isRequired,
}

export default Provider
