import HeroText from './HeroText'
import HeroTextTitle from './HeroTextTitle'
import HeroTextBody from './HeroTextBody'
import HeroTextIntro from './HeroTextIntro'

HeroText.Title = HeroTextTitle
HeroText.Body = HeroTextBody
HeroText.Intro = HeroTextIntro

export default HeroText
