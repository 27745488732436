import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'

const BlockCtaIcon = ({ icon, ...props }) => <Icon icon={icon} {...props} />

BlockCtaIcon.propTypes = {
  icon: PropTypes.string.isRequired,
}

export default BlockCtaIcon
